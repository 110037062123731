.unit-request-form-v1 {
	.bg-banner {
		background-image: url(RESOURCE/img/clouds-bg.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.checkbox label {
		vertical-align: inherit;
	}



	.unit-pic {
		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			opacity: 0.5
		}
	}
}