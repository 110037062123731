.about-us-v1 {
	margin-top: var(--page-margin-top);

	p {
		text-align: justify;
	}

	.heading1 {
		.small {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}

	.team-members {
		/*display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;*/
		display: flex;
		justify-content: space-between;

		.member {
			border: 0;
			border-radius: 0;
			width: 270px;

			@media (max-width: 992px) {
				width: 100%;

			}
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);
		padding: 10px 1em !important;

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}