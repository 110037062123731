.regionen-v1 {
	margin-top: var(--page-margin-top);

	p {
		text-align: justify;
	}

	.heading1 {
		margin-bottom: 50px;

		.small {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
}