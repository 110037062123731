.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.doc-box {
		.privacy-link {
			position: relative;
			top: -2px;
		}
	}

	.travel-field {
		.guest-box {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fa-angle-down {
				position: absolute;
				right: 12px;
				top: 6px;
				color: var(--color-grey-dark);
				pointer-events: none;
				font-weight: 900;
				font-family: Font Awesome\ 5 Free;
				font-style: normal;

				&:before {
					content: "\f107";
				}

				@media(max-width:992px) {
					right: 0px;
					top: 15px;
				}
			}

		}
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.dlg {
		.header {
			padding: 12px 15px;

			h4 {
				font-size: var(--h2-font-size);
				font-weight: var(--h2-font-weight);
				margin: 0;
				font-family: var(--font-family-main);
			}
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 220px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 200px;
			margin-bottom: 57px;
		}

	}

}