.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-body p {
			font-size: 14px;
		}

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 10px 30px 10px 15px;

			.btn {
				padding: 0;
			}
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--font-color-main);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-family: 'OpenSans';
		font-size: 14px;
		font-weight: 600;

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	h2 {
		font-family: var(--font-family-main);
		font-weight: 400;
		font-size: 18px;
	}
}