.agb-v1 {
	margin-top: var(--page-margin-top);

	p {
		text-align: justify;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/banner-1.jpg);
	}
}